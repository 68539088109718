<template>
  <section class="directories">
    <div class="directories__list">
      <div v-for="directory of directories" :key="directory.name" :class="{active: selectedDirectory == directory.component}" @click="selectedDirectory = directory.component">{{ directory.name }}</div>
    </div>
    <component :is="selectedDirectory"></component>
  </section>
</template>

<script>
import GenreMain from './directories/GenreMain';
import LanguageMain from './directories/LanguageMain';
import ExecutorMain from './directories/ExecutorMain';
import AuthorMain from './directories/AuthorMain';
import FilmMain from './directories/FilmMain';
import FilterMain from './directories/FilterMain';

export default {
  name: 'DirectoryMain',
  data() {
    return {
      selectedDirectory: GenreMain,
      directories: [
        { name: 'Жанры', component: GenreMain },
        { name: 'Языки', component: LanguageMain },
        { name: 'Исполнители', component: ExecutorMain },
        { name: 'Авторы', component: AuthorMain },
        { name: 'Фильмы', component: FilmMain },
        { name: 'Сайт-фильтры', component: FilterMain },
      ],
    }
  },
  mounted() {
    
  },
}
</script>

<style lang="scss" scoped>
.directories {
  flex-grow: 1;
  padding: 25px;
  overflow: auto;
  &__list {
    display: flex;
    margin-bottom: 30px;
    > div {
      color: #00325C;
      font-weight: 500;
      font-size: 20px;
      cursor: pointer;
      padding: 5px 12px 15px 12px;
      &:not(:last-child) {
        margin-right: 5px;
      }
      &:hover {
        color: #054b85;
        transition: 0.3s all;
      }
      &.active {
        box-shadow: inset 0 -3px 0 #6461F6;
      }
    }
  }
  .directory {
    display: flex;
    flex-direction: column;
  }
}
</style>