<template>
  <DirectoryMain />
</template>

<script>
import DirectoryMain from '@/components/directory/DirectoryMain';

export default {
  name: 'Directory',
  components: {
    DirectoryMain
  }
}
</script>

<style>

</style>