<template>
  <div class="directories__container general">
    <CommonTitle :options="options" />
    <CommonTable :options="options" />
  </div>
</template>

<script>
import Axios from 'axios';
import CommonTitle from '../../common/CommonTitle';
import CommonTable from '../../common/CommonTable';

export default {
  name: 'GenreMain',
  data() {
    return {
      options: {add: 'Добавить жанр', actions: 'edit-remove-add', grid: '1 / 2', id: 'genre', request: 'genres', name: 'Жанры', table: {
          variables: ['name'],
          page: 0,
          limit: 10,
          total: 0,
          data: []
        }, ths: [
          {id: 'name', name: 'Жанр', value: '', sort: true, sort_data: '', search: true, width: '80%'},
          {id: '', name: '', width: '60px'},
      ]},
    }
  },
  mounted() {
    Axios
      .get(`/api/genres`, {
        headers: {
          token: this.$store.getters.token
        },
        params: {
          limit: 10,
          page: 0
        }
      })
      .then(res => {
        this.options.table.data = res.data.data;
        this.options.table.page = 0;
        this.options.table.limit = 10;
        this.options.table.total = res.data.total;
      })
      .catch(err => {
        console.error(err.response);
      })

    this.$bus.$on('createDirectory', (result) => {
      this.options.table.data.unshift(result.data);
    });
    this.$bus.$on('editDirectory', (result) => {
      const directory = this.options.table.data.find(el => el._id === result.data._id);
      if (directory) {
        directory.name = result.data.name;
        if (directory.description)
          directory.description = result.data.description;
      }
    });
    this.$bus.$on('removeDirectory', (result) => {
      this.options.table.data = this.options.table.data.filter(el => el._id !== result.data);
    });
  },
  components: {
    CommonTitle,
    CommonTable
  }
}
</script>

<style lang="scss" scoped>
.directories__container {
    width: 50%;
}
</style>